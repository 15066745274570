import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Album from "../components/album"

const Music = ({ data }) => (
  <Layout>
    <SEO title="Music" />
    <StyledMusicPage>
      <div className="albums">
        <Album link="https://onerpm.link/122602135463" image={data.DM.edges[0].node} /> 
        <Album link="https://onerpm.link/jumpingthegun" image={data.JTG.edges[0].node} /> 
        <Album link="https://vibe.to/kayla" image={data.JB.edges[0].node} /> 
        {/* <Album link="https://vibe.to/thisisntfunanymore" image={data.TIFA.edges[0].node} /> */}
        {/* <Album link="https://vibe.to/youth" image={data.Youth.edges[0].node} /> */}
      </div>
    </StyledMusicPage>
  </Layout>
)

const StyledMusicPage = styled.div`
  .albums {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
`

export const pageQuery = graphql`
query QueryAlbums {
  JB:allFile(filter: {name: {eq: "justinbieber"}}) {
    edges {
      node {
        id
        name
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
  
  DM:allFile(filter: {name: {eq: "datemyself"}}) {
    edges {
      node {
        id
        name
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
  JTG:allFile(filter: {name: {eq: "jumpingthegun"}}) {
    edges {
      node {
        id
        name
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
}
`

export default Music
