import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const Album = ({ link, image, ...rest }) => (
  <StyledAlbum {...rest}>
    <a href={link} target="_blank" rel="noreferrer noopener" >
      <div className="album-cover">
        <GatsbyImage
          image={getImage(image)}
          width={250}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Kayla DiVenere"
        />
      </div>

      <h2>listen</h2>
    </a>
  </StyledAlbum>
)

const StyledAlbum = styled.div`
  margin-bottom: 30px;

  h2 {
    text-align: center;
    margin: 10px 0 0 0;
  }

  a, a:visited {
    color: ${({ theme }) => theme.color.red};
  }
  a:hover {
    color: ${({ theme }) => theme.color.blue};
  }

  @media (min-width: ${({ theme }) => theme.size.sm}px) {
    margin: 0 auto 40px auto;

    .album-cover {
      max-width: 200px;
    }
  }
`


Album.defaultProps = {
  link: '/',
  image: '',
}

Album.propTypes = {
  link: PropTypes.string,
  image: PropTypes.any,
}

export default Album
